import { useEffect, useState } from 'react';
import { Button, Grid, Group, ScrollArea, Text, TextInput } from '@mantine/core';

interface ItemInterface {
    p: string | null,
    c: string | null,
    l: string,
}
interface PropsInterface {
    label: string,
    parents: ItemInterface[],
    children: ItemInterface[],
    handle: any,
}

const SelectorContainer = ({label, parents, children, handle}: PropsInterface) => {
    
    const [parent, setParent] = useState<string | null>(null);
    const [terms, setTerms] = useState<string[]>([]);
    const [search, setSearch] = useState('');
    const [querySearch, setQuerySearch] = useState<RegExp | null>(null);

    const addValue = (value: string) => {
        if (value === '') return;
        if (terms.indexOf(value) !== -1) return;
        setTerms(terms.concat([value, ]));
    };

    const removeValue = (value: string) => {
        if (value === '') return;
        const p = terms.indexOf(value);
        if (p === -1) return;
        setTerms(terms.filter((s,i) => p !== i));
    }

    useEffect(() => {
        handle([...terms]);
    }, [terms]); // eslint-disable-line

    useEffect(() => {
        if (search.trim() === '') {
            setQuerySearch(null);
        } else {
            setQuerySearch(new RegExp(search.trim(), 'i'));
        }
    }, [search]); // eslint-disable-line

    return (
        <Grid>
            <Grid.Col span={4}>
                {parents.length !== 0 &&
                <>
                <Text>Filter par famille :</Text>
                <ScrollArea style={{height: '120px'}}>
                    {parents.map((s,i) => 
                    <Group key={`parent-${i}`}>
                        <Button color={s.c === parent ? 'teal' : 'blue'} variant='outline' compact
                            onClick={() => (parent === s.c) ? setParent(null) : setParent(s.c)}
                        >
                            {s.l}
                        </Button>
                    </Group>
                    )}
                </ScrollArea>
                </>}
            </Grid.Col>
            <Grid.Col span={4}>
                <TextInput 
                    size='xs'
                    placeholder="mot"
                    label="Rechercher"
                    value={search} onChange={(event) => setSearch(event.currentTarget.value)}
                />
                <ScrollArea style={{height: '120px'}}>
                    {children.filter((s) => (parent === null || parent === s.p) && (querySearch === null || querySearch.test(s.l))).map((s,i) => 
                    <Group key={`child-${i}`}>
                        <Button color='blue' variant='outline' compact
                            onClick={() => addValue(s.l)}
                        >
                            {s.l}
                        </Button>
                    </Group>
                    )}
                </ScrollArea>
            </Grid.Col>
            <Grid.Col span={4}>
                <ScrollArea style={{height: '120px'}} mt={50}>
                    {terms.map((s,i) => 
                    <Group key={`term-${i}`}>
                        <Button color='teal' variant='outline' compact
                            onClick={() => removeValue(s)}
                        >
                            {s}
                        </Button>
                    </Group>
                    )}
                </ScrollArea>
            </Grid.Col>
        </Grid>
    )

}

export { SelectorContainer }