import { useContext } from 'react';
import { Flex, Header, Image, Stack, Text } from '@mantine/core';
import logo from '../../../logo.png';
import AppContext from '../AppContext';

const HeaderContainer = () => {

    const myContext = useContext(AppContext);
    
    return (
        <Header height={45}>
            <Flex justify='center'>
                <Stack spacing={0}>
                    <Image 
                        width={33}
                        height={33}
                        src={logo} 
                        alt='Logo SNR PARTNERS, management de transition et recrutement' 
                    />
                    <Text size={8} pl='xs'>v{myContext.version}</Text>
                </Stack>
                <Stack spacing={0}>
                    <Text color="#E81C34" pl="xs"><strong>SNR PARTNERS</strong></Text>
                    <Text color="#E81C34" pl="xs" size="sm"><strong>management de transition <i>et</i> recrutement</strong></Text>
                </Stack>
                <Text pr='lg'>&nbsp;</Text>
            </Flex>
        </Header>
    )
}

export { HeaderContainer }