import { useContext, useEffect, useReducer, useState } from 'react';
import { useParams } from "react-router-dom";
import { ActionIcon, Alert, Card, Badge, Box, Button, Center, Flex, Group, Loader, LoadingOverlay, Modal, NumberInput, Progress, Stack, Text, TextInput, Textarea } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import 'dayjs/locale/fr';
import AppContext from '../shared/AppContext';
import { IconArrowBigLeft, IconArrowBigRight } from '@tabler/icons';

type ParamInterface = {
    paramToken: string,
}

interface RowInterface {
    // position
    position: number,
    // D => Date
    // E => Email
    // I => Réponse libre
    // N => Valeur numérique entière
    // P => Phone number
    // R => Réponse oui / non
    // S => Slider
    // T => Texte pour information
    // Z => Code postal
    nature: 'D' | 'E' | 'I' | 'N' | 'P' | 'R' | 'S' | 'T' | 'Z',
    // obligatoire ?
    required: boolean,
    // code
    code: string,
    // contenu
    label: string,
    // value
    value: string | number | null,
    // est valide ?
    valid: boolean,
    // paramètres éventuels
    param: any,
}
interface FormInterface {
    isValid: boolean,
    hasChanged: boolean,
    rows: RowInterface[],
}
const formReducer = (state: FormInterface, payload: any) => {
    switch(payload.type) {
        case 'set':
            return { hasChanged: false, isValid: false, rows: payload.data.map((row: any) => { return {...row, valid: false}}) };
        case 'update':
            const rows: RowInterface[] = state.rows.map((r:RowInterface,i:number) => {
                if (i === payload.idx) {
                    return {...r, value: payload.value };
                } else {
                    return {...r};
                }
            });
            let ok: boolean = true;
            for(let i=0;i<rows.length;i++) {
                if (rows[i].nature === 'T') continue;
                if (rows[i].required && (
                    rows[i].value === '-' || rows[i].value === '' || rows[i].value === null
                )) {
                    rows[i].valid = false;
                    ok = false;
                    break;
                } else if (rows[i].value !== '' && rows[i].value !== null) {
                    let pattern = null;
                    switch(rows[i].nature) {
                        case 'D':
                            pattern = /^20[0-9]{2}-(0|1)[0-9]-(0|1|2|3)[0-9]{1}$/;
                            break;
                        case 'E':
                            pattern = /^[a-z0-9._-]{0,100}@[a-z0-9._-]{0,50}\.[a-z]{2,6}$/
                            break;
                        case 'I':
                            const l = (rows[i].value + '').trim().length;
                            rows[i].valid = (l > 1 && l < 1000) ? true : false;
                            break;
                        case 'N':
                            pattern = /(^0$)|(^[1-9][0-9]{0,9}$)/;
                            break;
                        case 'P':
                            pattern = /^(00( |.){0,1}[0-9]{2,3}( |.){0,1}[0-9]{1}){0,1}(( |.){0,1}[0-9]{2}){4,6}$/;
                            break;
                        case 'R':
                            pattern = /^(oui)|(non)$/;
                            break;
                        case 'S':
                            rows[i].valid = true;
                            break;
                        case 'Z':
                            pattern = /^[0-9]{5}$/;
                            break;
                    }
                    if (pattern !== null) {
                        rows[i].valid = (pattern.test(rows[i].value + '')) ? true : false;
                        if (rows[i].valid === false) {
                            ok = false;
                        }
                    }
                }
            }
            return {
                isValid: ok,
                hasChanged: true,
                rows: rows,
            }
        default:
            return { hasChanged: false, isValid: false, rows: [] };
    }
}

const FormPage = () => {

    const myContext = useContext(AppContext);
    const { paramToken } = useParams<ParamInterface>();
    const [loaded, setLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [finished,setFinished] = useState(false);
    const [form, dispatchForm] = useReducer(formReducer, {hasChanged: false, isValid: false, rows: []});
    const [error, setError] = useState<string | null>(null);

    const [active, setActive] = useState(0);
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (loaded) return;
        const F = new FormData();
        F.append('dom', 'SNR');
        F.append('action', 'get_form');
        F.append('token', paramToken || 'undefined');
        const api = `${myContext.apiAddress}/public`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setLoaded(true);
            if (res.data.status === true) {
                dispatchForm({type: 'set', data: res.data.data});
            } else {
                setError(res.data.message || 'Nous avons rencontré une erreur inattendue.');
            }
        });
    }, [loaded]); // eslint-disable-line

    useEffect(() => {
        if (!loaded) return;
        if (active === 0) {
            setTitle('-');
        } else {
            let i = active;
            while (i > 0) {
                i -= 1;
                if (form.rows[i].nature === 'T') {
                    break;
                }
            }
            if (i >= 0) {
                setTitle(form.rows[i].label);
            } else {
                setTitle('');
            }
        }
    }, [loaded, active]); // eslint-disable-line

    const save = () => {
        setSaving(true);
        const F = new FormData();
        F.append('dom', 'SNR');
        F.append('action', 'save_form');
        F.append('token', paramToken || 'undefined');
        F.append('rows', JSON.stringify(form.rows));
        const api = `${myContext.apiAddress}/public`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setSaving(false);
            if (res.data.status === true) {
                setFinished(true);
            } else {
                setError(res.data.message || 'Nous venons de rencontrer une erreur inattendue.');
            }
        });
    }

    const toDate = (s: string | null) => {
        if (typeof s !== 'string') {
            return null;
        } else {
            if (s.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
                return new Date(s);
            } else {
                return null;
            }
        }
    }

    const questionBloc = (i: number) => {
        const nature = form.rows[i].nature;
        if (nature === 'D') {
            return (
                <DatePicker
                    locale="fr"
                    label={form.rows[i].label}
                    value={toDate(form.rows[i].value + '')}
                    onChange={(d: Date | null) => {
                        dispatchForm({type: 'update', idx: i, value: d === null ? null : d.getFullYear() + '-' + (d.getMonth() < 9 ? '0' + (d.getMonth()+1) : d.getMonth()+1) + '-' + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate()) })
                    }}
                    disabled={finished}
                />
            )
        } else if (nature === 'E') {
            return (
                <TextInput mt='xs'
                    placeholder="login@domain.com"
                    label="Votre adresse email"
                    value={(form.rows[i].value === null) ? '' : form.rows[i].value + ''} 
                    onChange={(event) => dispatchForm({type: 'update', idx: i, value: event.currentTarget.value.toLocaleLowerCase().replace(/[^0-9a-z@._-]/g, '')})}
                    disabled={finished}
                />
            )
        } else if (nature === 'I') {
            return (
                <Stack spacing={0} style={{width: '100%'}}>
                    <Text mb={12}>
                        {form.rows[i].label}
                    </Text>
                    <Textarea 
                        value={form.rows[i].value === null ? '' : form.rows[i].value + ''}
                        color="blue"
                        onChange={(event) => dispatchForm({type: 'update', idx: i, value: event.target.value})}
                        minRows={3} 
                        maxRows={10} 
                        autosize 
                        disabled={finished}
                        style={{width: '100%'}}
                    />
                    {(form.rows[i].value + '').length >= 1000 ?
                    <Text color='red' align='center'>texte trop long, merci de la raccourcir</Text>
                    :
                    <Text size='sm' color='dimmed' align='right'>{1000 - (form.rows[i].value+'').length} caractères restants</Text>
                    }
                </Stack>
            )
        } else if (nature === 'N') {
            return (
                <Stack spacing={0} style={{width: '100%'}}>
                    <Text mb={12}>
                        {form.rows[i].label}
                    </Text>
                    <Center>
                        <NumberInput 
                            min={0} 
                            max={500000}
                            hideControls
                            value={form.rows[i].value === null ? undefined : parseInt(form.rows[i].value+'')}
                            onChange={(val) => dispatchForm({type: 'update', idx: i, value: val === undefined ? null : val})}
                            disabled={finished}
                        />
                    </Center>
                </Stack>
            )
        } else if (nature === 'P') {
            return (
                <TextInput mt='xs'
                    placeholder="06 10 20 30 40"
                    label="Votre numéro de téléphone portable"
                    value={(form.rows[i].value === null) ? '' : form.rows[i].value + ''} 
                    onChange={(event) => dispatchForm({type: 'update', idx: i, value: event.currentTarget.value.replace(/[^0-9 .]/, '').replace(/[ ]+/, ' ',)})}
                    disabled={finished}
                />
            )
        } else if (nature === 'R') {
            return (
                <Stack spacing={0} style={{width: '100%'}}>
                    <Text mb={12} >
                        {form.rows[i].label}
                    </Text>
                    <Group position='center'>
                        {finished ?
                        <>
                        <Badge color='gray'
                            variant={form.rows[i].value === 'oui' ? 'filled' : 'outline'}
                            size='sm'
                        >
                            yes - oui
                        </Badge>
                        <Badge color='gray'
                            variant={form.rows[i].value === 'non' ? 'filled' : 'outline'}
                            size='sm'
                        >
                            no - non
                        </Badge>
                        </>
                        :
                        <>
                        <Button color='blue'
                            onClick={() => dispatchForm({type: 'update', idx: i, value: 'oui'})}
                            variant={form.rows[i].value === 'oui' ? 'filled' : 'outline'}
                            compact
                            size='sm'
                        >
                            yes - oui
                        </Button>
                        <Button color='blue'
                            onClick={() => dispatchForm({type: 'update', idx: i, value: 'non'})}
                            variant={form.rows[i].value === 'non' ? 'filled' : 'outline'}
                            compact
                            size='sm'
                        >
                            no - non
                        </Button>
                        </>
                        }
                    </Group>
                </Stack>
            )
        } else if (nature === 'S') {
            return (
                <Stack spacing={0} style={{width: '100%'}}>
                    <Text mb={12}>
                        {form.rows[i].label}
                    </Text>
                    <Group position="center">
                        {finished ?
                        <>
                        {form.rows[i].param.map((row:any, j:number) =>
                        <Badge key={`q-${i}-${j}`} color='gray'
                            variant={form.rows[i].value === row.label ? 'filled' : 'outline'}
                            size='sm'
                        >
                            {row.label}
                        </Badge>
                        )}
                        </>
                        :
                        <>
                        {form.rows[i].param.map((row:any, j:number) =>
                        <Button key={`q-${i}-${j}`} color='blue'
                            onClick={() => dispatchForm({type: 'update', idx: i, value: row.label})}
                            variant={form.rows[i].value === row.label ? 'filled' : 'outline'}
                            compact
                            size='sm'
                        >
                            {row.label}
                        </Button>
                        )}
                        </>
                        }
                    </Group>
                </Stack>
            )
        } else if (nature === 'T') {
            return (
                <Stack spacing={0} style={{width: '100%'}}>
                    <Text color='dimmed' size='sm'>
                        {i === 0 ? 'Questionnaire' : 'Section'}
                    </Text>
                    <Text size='lg'>
                        {form.rows[i].label}
                    </Text>
                </Stack>
            )
        } else if (nature === 'Z') {
            return (
                <Stack spacing={0} style={{width: '100%'}}>
                    <TextInput mt='xs'
                        placeholder="00000"
                        label="Zipcode - Code postal"
                        value={form.rows[i].value === null ? '' : form.rows[i].value + ''} 
                        onChange={(event) => dispatchForm({type: 'update', idx: form.rows[i].position, value: event.currentTarget.value.replace(/[^0-9]/g, '')})}
                        disabled={finished}
                    />
                    <Text size='sm'>
                        Il s'agit du code postal de votre localité principale.
                    </Text>
                    <Text size='sm'>
                        Si vous n'en disposez pas en France, alors mettez 00000 (cinq zéros).
                    </Text>
                </Stack>
            )
        } else {
            return (
                <><Text color='red'>Oops, unknown nature.</Text></>
            )
        }
    }

    if (loaded) {
        return (
            <Center>
                <Box style={{maxWidth: '700px'}}>
                    <LoadingOverlay visible={saving} />
                    
                    <Modal
                        opened={error !== null}
                        onClose={() =>setError(null)}
                    >
                        <Center p='lg'>
                            <Alert title="Erreur" color="red">
                                {error}
                            </Alert>
                        </Center>
                    </Modal>

                    {(form.rows.length === 0) &&
                    <Center p='lg'>
                        <Alert title="Erreur" color="red">
                            Ce formulaire a déjà été rempli par une personne. Si cette personne est vous, merci de nous appeler, ou nous écrire, pour nous faire part d'une modification. Merci
                        </Alert>
                    </Center>
                    }
                    
                    {form.rows.length > 0 &&
                    <>

                    <Stack spacing={0} mb='lg'>
                        <Text color='dimmed' size='xs'>
                            Avancement
                        </Text>
                        <Progress 
                            value={Math.round(100 * (active + 1) / form.rows.length)} 
                        />
                    </Stack>

                    <Text lineClamp={1} size='sm' color='dimmed' style={{maxWidth: '350px'}}>
                        {title}
                    </Text>

                    <Card  
                        style={{overflow: 'visible', minHeight: '200px', width: '350px'}}
                        withBorder
                    >
                        <Flex
                            justify="flex-start"
                            align="flex-start"
                            direction="column"
                            wrap="nowrap"
                        >
                            {active < form.rows.length ?
                            <>
                            {form.rows[active].required &&
                                <>
                                {form.rows[active].valid ?
                                <Text color='green' size='xs' align='right' style={{width: '100%'}}>
                                    ✓
                                </Text>
                                :
                                <Text color='red' size='xs'>
                                    réponse requise
                                </Text>
                                }
                                </>
                            }
                            {questionBloc(active)}
                            </>
                            :
                            <>
                            <Text size='sm' mt='lg'>
                                En nous transmettons vos réponses, vous acceptez alors nos conditions générales d'utilisation.
                            </Text>
                            <Center m='lg'>
                                <Button color="blue" variant="outline" 
                                    disabled={!form.hasChanged || !form.isValid || finished}
                                    onClick={() => save()}
                                >
                                    Submit to finish - Valider pour finir
                                </Button>
                            </Center>
                            </>
                            }
                        </Flex>
                    </Card>
                    
                    <Group position='center' mt='lg'>
                        {(active > 0) &&
                        <ActionIcon 
                            color='blue'
                            variant='filled'
                            onClick={() => setActive(active-1)}
                            disabled={active === 0}
                        >
                            <IconArrowBigLeft size={16} />
                        </ActionIcon>
                        }
                        
                        {(active < form.rows.length) &&
                        <ActionIcon 
                            color='blue'
                            variant='filled'
                            onClick={() => setActive(active+1)}
                            disabled={
                                (form.rows[active].required && !form.rows[active].valid) || 
                                (!form.rows[active].required && form.rows[active].value !== null && form.rows[active].value !== '' && !form.rows[active].valid)
                            }
                        >
                            <IconArrowBigRight size={16} />
                        </ActionIcon>
                        }

                    </Group>

                    {finished && 
                    <Card style={{overflow: 'visible', minHeight: '200px', width: '350px'}} >
                        <Alert title="Thank you - Merci" color="green">
                            <Text>Vos réponses ont bien été prises en compte.</Text>
                            <Text>Merci de noter que ce formulaire ne pourra plus être modifié par la suite.</Text>
                            <Text>Au besoin, vous pouvez nous contacter.</Text>
                        </Alert>
                    </Card>
                    }

                    </>
                    }

                </Box>
            </Center>
        )
    } else {
        return (
            <Center>
                <Group>
                    <Loader color="red"/>
                    <Text>Nous chargeons votre questionnaire&hellip;</Text>
                </Group>
            </Center>
        )
    }
    
}

export { FormPage }