import { Link } from 'react-router-dom';
import { Center, Footer, Text } from '@mantine/core';
import { IconPhoneCall, IconSend } from '@tabler/icons';

const FooterContainer = () => {

    return (
        <Footer height={35}>
            <Center pb="xs" pt="xs">
                <Text color="dimmed" size="xs" pr="lg"
                    sx={{
                        '@media (max-width: 700px)': {
                            display: 'none',
                        }
                    }}
                >
                    SNR Partners, 5 rue Agar, 75016 Paris
                </Text>
                <Text component="a" href="mailto:contact@snr-partners.com" size="sm" pr="lg">
                    <IconSend size={14}/> contact@snr-partners.com
                </Text>
                <Text component="a" href="tel:+33146454492" size="sm">
                    <IconPhoneCall size={14}/> 01.46.45.44.92
                </Text>
                <Text component={Link} to="/mentions" color="dimmed" size="xs" pl="lg"
                    sx={{
                        '@media (max-width: 700px)': {
                            display: 'none',
                        }
                    }}
                >
                    mentions légales
                </Text>
            </Center>
        </Footer>
    )
}

export { FooterContainer }