import { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Alert, Badge, Card, Center, Stepper, Button, Group, Modal, ScrollArea, Stack, Text, TextInput } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { IconAlertCircle, IconCheck, IconReload, IconSend } from '@tabler/icons';
import AppContext from '../shared/AppContext';
import { listOfJobsParents, listOfJobs, listOfSectors } from './repository_snr';
import { SelectorContainer } from './selector';
import { MentionsPage } from '../MentionsPage';

// PDFJS
// cp ./node_modules/pdfjs-dist/build/pdf.worker.min.js ./public
import * as pdfjs from 'pdfjs-dist';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type ParamInterface = {
    paramDomain: string,
    paramSource: string | undefined,
}

const JoinPage = () => {

    const myContext = useContext(AppContext);
    const { paramDomain, paramSource } = useParams<ParamInterface>();

    // On définit les fonctions de contrôle.
    const checkEmail = (s: string) => {
        return (s.length > 0 && /^[\w-.]+@([\w-]+\.){1,3}[\w]{2,4}$/.test(s));
    }
    const checkName = (s: string) => {
        return (s.trim().length > 0 && /^[a-zàáâãäåòóôõöøèéêëçìíîïùúûüÿñ' -]+$/.test(s.trim().toLocaleLowerCase()));
    }
    const checkPhone = (s:string) => {
        return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(s.replaceAll(' ', ''));
    }
    const checkCode = (s: string) => {
        return /^[0-9]{5}$/.test(s);
    }
    const checkZipcode = (s:string) => {
        return /^[0-9]{5}$/.test(s);
    }

    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 5 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
    
    const [inputFirstname, setInputFirstname] = useState('');
    const [testFirstname, setTestFirstname] = useState(false);
    useEffect(()=> {
        setTestFirstname(checkName(inputFirstname));
    }, [inputFirstname]);
    
    const [inputLastname, setInputLastname] = useState('');
    const [testLastname, setTestLastname] = useState(false);
    useEffect(()=> {
        setTestLastname(checkName(inputLastname));
    }, [inputLastname]);
    
    const [inputPhone, setInputPhone] = useState('');
    const [testPhone, setTestPhone] = useState(false);
    useEffect(()=> {
        setTestPhone(checkPhone(inputPhone));
    }, [inputPhone]);

    const [inputEmail, setInputEmail] = useState('');
    const [testEmail, setTestEmail] = useState(false);
    useEffect(()=> {
        setTestEmail(checkEmail(inputEmail));
    }, [inputEmail]);

    const [inputZipcode, setInputZipcode] = useState('');
    const [testZipcode, setTestZipcode] = useState(false);
    useEffect(()=> {
        setTestZipcode(checkZipcode(inputZipcode));
    }, [inputZipcode]);

    const [inputCode, setInputCode] = useState('');
    const [testCode, setTestCode] = useState(false);
    useEffect(()=> {
        if (checkCode(inputCode)) {
            setTestCode(true);
            setEmailStatus(3);
        } else {
            setTestCode(false);
            if (emailStatus > 2) {
                setEmailStatus(2);
            }
        }
    }, [inputCode]); // eslint-disable-line

    // Processus de contrôle de l'email :
    // 0 : on attend que l'utilisateur demande l'envoi d'un code
    // 1 : on demande à l'API d'envoyer un code (join?action=send-code)
    // 2 : (API ok) on demande à l'utilisateur de saisir le code reçu
    // 3 : l'utilisateur a saisi le code
    // 4 : on demande à l'API de vérifier le code transmis
    // 5 : l'API a répondu (sinon retour en 3)
    const [emailStatus, setEmailStatus] = useState(0);
    const [token, setToken] = useState('');

    const [errorMessage,setErrorMessage] = useState<null | string>(null);

    const askForCode = () => {
        const F = new FormData();
        F.append('domain', `${paramDomain}`);
        F.append('action', 'send_code');
        F.append('email', inputEmail);
        F.append('firstname', inputFirstname);
        const api = `${myContext.apiAddress}/join`;
        setEmailStatus(1);
        myContext.httpClient.post(api, F).then((res: any) => {
            if (res.data.status === true) {
                setEmailStatus(2);
            } else {
                setEmailStatus(0);
                setErrorMessage(res.data.message || 'unknown error');
            }
        });
    }

    const askCheckCode = () => {
        const F = new FormData();
        F.append('domain', `${paramDomain}`);
        F.append('source', `${paramSource}`);
        F.append('action', 'check_code');
        F.append('firstname', inputFirstname);
        F.append('lastname', inputLastname);
        F.append('phone', inputPhone);
        F.append('email', inputEmail);
        F.append('code', inputCode);
        F.append('zipcode', inputZipcode);
        const api = `${myContext.apiAddress}/join`;
        setEmailStatus(4);
        myContext.httpClient.post(api, F).then((res: any) => {
            if (res.data.status === true) {
                setEmailStatus(5);
                setToken(res.data.message);
                nextStep();
            } else {
                setEmailStatus(2);
                setErrorMessage(res.data.message || 'unknown error');
            }
        });
    }

    const askResetCode = () => {
        setEmailStatus(0);
        setInputCode('');
    }

    // Fichier.
    // 0 : aucun fichier
    // 1 : fichier slectionné
    // 2 : on démarre l'extraction du texte
    // 3 : le texte est extrait
    // 4 : on demande à l'API de le charger
    // 5 : fichier chargé par l'API
    const [ fileStatus, setFileStatus ] = useState(0);
    const [ inputFile, setInputFile ] = useState<File | null>(null);
    const [ pdfContent, setPdfContent ] = useState('');
    
    useEffect(() => {
        if (inputFile === null) return;
        setFileStatus(2);
        setErrorMessage(null);
        setPdfContent('');
        var fr = new FileReader();
        fr.onload = async (e) => {
            if (e.target !== null) {
                const data = e.target.result;
                if (data !== null) {
                    let doc = await pdfjs.getDocument(data).promise;
                    let pageTexts = Array.from({length: doc.numPages}, async (v,i) => {
                        return (await (await doc.getPage(i+1)).getTextContent()).items.map((token:any) => token.str).join(' ');
                    });
                    const t = (await Promise.all(pageTexts)).join(' ');
                    setPdfContent(t);
                    setFileStatus(3);
                }
            }
        }
        fr.readAsArrayBuffer(inputFile);
    }, [inputFile]);

    useEffect(() => {
        if (inputFile === null) return;
        if (fileStatus < 3) return;
        if (pdfContent.length < 100) {
            setErrorMessage(inputFile.name + ' : échec de lecture du contenu de votre CV, merci de ne pas utiliser un PDF image.');
        } else {
            setErrorMessage(null);
        }
    }, [pdfContent]); // eslint-disable-line

    const sendFile = () => {
        if (inputFile === null) return;
        if (pdfContent.length < 100) return;
        const F = new FormData();
        F.append('domain', `${paramDomain}`);
        F.append('action', 'file');
        F.append('token', token);
        F.append('document', inputFile);
        F.append('content', pdfContent);
        const url = `${myContext.apiAddress}/join`;
        setFileStatus(4);
        myContext.httpClient.post(url, F, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res: any) => {
            if (res.data.status === true) {
                setFileStatus(5);
                nextStep();
            } else {
                setFileStatus(3);
                setErrorMessage(res.data.message || 'unknown error');
            }
        });
    }

    // Métiers et secteurs.
    // 0 on démarre
    // 1 on a au moins un métier et un secteur
    // 2 on demande à l'API
    // 3 données sauvegardées
    const [repoStatus, setRepoStatus] = useState(0);
    const [inputJobs, setInputJobs] = useState<string[]>([]);
    const [inputSectors, setInputSectors] = useState<string[]>([]);
    useEffect(() => {
        if (inputJobs.length !== 0 && inputSectors.length !== 0) {
            setRepoStatus(1);
        } else {
            setRepoStatus(0);
        }
    }, [inputJobs, inputSectors]);

    const sendRepo = () => {
        if (inputJobs.length === 0 || inputSectors.length === 0) return;
        setRepoStatus(2);
        const url = `${myContext.apiAddress}/join`;
        const F = new FormData();
        F.append('domain', `${paramDomain}`);
        F.append('token', token);
        F.append('action', 'repo');
        F.append('jobs', '"' + inputJobs.join('";"') + '"');
        F.append('sectors', '"' + inputSectors.join('";"') + '"');
        myContext.httpClient.post(url, F).then((res: any) => {
            if (res.data.status === true) {
                setRepoStatus(3);
                nextStep();
            } else {
                setRepoStatus(1);
                setErrorMessage(res.data.message || 'unknown error');
            }
        });
    }

    // Validation finale du dossier.
    // 0 init
    // 1 checkbox validée, on demande à l'API
    // 2 c'est bon
    const [finalStatus, setFinalStatus] = useState(0);
    const [displayAgreement, setDisplayAgreement] = useState(false);
    const sendAgreement = () => {
        setFinalStatus(1);
        const url = `${myContext.apiAddress}/join`;
        const F = new FormData();
        F.append('domain', `${paramDomain}`);
        F.append('token', token);
        F.append('action', 'cg');
        myContext.httpClient.post(url, F).then((res: any) => {
            if (res.data.status === true) {
                setFinalStatus(2);
                nextStep();
            } else {
                setFinalStatus(0);
                setErrorMessage(res.data.message || 'unknown error');
            }
        });
    }

    // Rendu :
    return (
        <>
        <Modal
            onClose={() => setDisplayAgreement(false)}
            opened={displayAgreement}
            title="Conditions générales d'utilisation"
            size="xl"
        >
            <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                <MentionsPage />
            </ScrollArea>
        </Modal>
        <Text align='center'><strong>
            Formulaire d'inscription pour rejoindre notre vivier de managers de transition
        </strong></Text>
        <Center>
            <Card maw={1000}>
                <Stepper active={active} size='xs' breakpoint="sm">

                    <Stepper.Step label="Identité">
                        <Card mih={300} withBorder>
                            <TextInput mt='xs'
                                placeholder="Exemple Jean"
                                label="Prénom"
                                value={inputFirstname} 
                                onChange={(event) => setInputFirstname(event.currentTarget.value)}
                                rightSection={<IconCheck size={16} color={testFirstname ? 'teal' : 'white'} />}
                                disabled={emailStatus !== 0}
                                withAsterisk
                            />
                            <TextInput mt='xs'
                                placeholder="Exemple DUPONT"
                                label="Nom de famille"
                                value={inputLastname} 
                                onChange={(event) => setInputLastname(event.currentTarget.value.toLocaleUpperCase())}
                                rightSection={<IconCheck size={16} color={testLastname ? 'teal' : 'white'} />}
                                disabled={emailStatus !== 0}
                                withAsterisk
                            />
                            <TextInput mt='xs'
                                placeholder="Exemple +33 6 12 34 56 78"
                                label="Téléphone"
                                value={inputPhone} 
                                onChange={(event) => setInputPhone(event.currentTarget.value)}
                                rightSection={<IconCheck size={16} color={testPhone ? 'teal' : 'white'} />}
                                disabled={emailStatus !== 0}
                                withAsterisk
                            />
                            <TextInput mt='xs'
                                placeholder="Exemple jean@dupont.com"
                                label="Email"
                                value={inputEmail} 
                                onChange={(event) => setInputEmail(event.currentTarget.value.trim().toLocaleLowerCase())}
                                rightSection={<IconCheck size={16} color={testEmail ? 'teal' : 'white'} />}
                                disabled={emailStatus !== 0}
                                withAsterisk
                            />
                            <TextInput mt='xs'
                                placeholder="00000"
                                label="Code postal"
                                value={inputZipcode} 
                                onChange={(event) => setInputZipcode(event.currentTarget.value.replace(/[^0-9]/g, ''))}
                                rightSection={<IconCheck size={16} color={testZipcode ? 'teal' : 'white'} />}
                                disabled={emailStatus !== 0}
                                withAsterisk
                            />
                            <Text size='sm'>
                                Il s'agit du code postal de votre localité principale.
                            </Text>
                            <Text size='sm'>
                                Si vous n'en disposez pas en France, alors mettez 00000 (cinq zéros).
                            </Text>
                        </Card>
                        <Group position="center" mt="xl">
                            <Button onClick={nextStep} disabled={!testFirstname || !testLastname || !testPhone || !testEmail || !testZipcode}>
                                Suivant
                            </Button>
                        </Group>
                    </Stepper.Step>

                    <Stepper.Step label="Vérification">
                        <Card mih={300} withBorder>
                            <Text>Vérification de votre adresse email <strong>{inputEmail}</strong></Text>
                            {emailStatus < 2 && 
                            <Card>
                                <Text>Merci de cliquer sur le bouton ci-après afin de recevoir un code de vérification à cette adresse.</Text>
                                <Group position="center" pt='xs' pb='xs'>
                                    <Button disabled={emailStatus > 1} loading={emailStatus === 1} 
                                        onClick={() => askForCode()} color='blue'
                                    >
                                        <IconSend size={16} />
                                    </Button>
                                </Group>
                            </Card>
                            }
                            {[2,3,4].indexOf(emailStatus) !== -1 &&
                            <Card>
                                <Center pt='xs' pb='xs'>
                                    <Stack>
                                        <Text color='orange'>Penser à regarder dans vos spams si le message n'apparaît pas dans votre inbox.</Text>
                                        <TextInput mt='xs'
                                            placeholder="12345"
                                            label="Merci de saisir ci-après le code de vérification reçu sur votre email :"
                                            value={inputCode} 
                                            onChange={(event) => setInputCode(event.currentTarget.value.trim())}
                                            rightSection={<IconCheck size={16} color={testCode ? 'teal' : 'white'} />}
                                            disabled={emailStatus !== 2 && emailStatus !== 3}
                                            withAsterisk
                                        />
                                        <Group position='center'>
                                            <Button disabled={emailStatus === 4}
                                                onClick={() => askResetCode()} variant='outline' color='blue'
                                            >
                                                <IconReload size={16} />
                                            </Button>
                                            <Button disabled={emailStatus < 3} loading={emailStatus === 4} 
                                                onClick={() => askCheckCode()} color='blue'
                                            >
                                                <IconSend size={16} />
                                            </Button>
                                        </Group>
                                    </Stack>
                                </Center>
                            </Card>
                            }

                            {emailStatus === 5 && 
                            <Card>
                                <Text color='teal'><strong>
                                    Email vérifié.
                                </strong></Text>
                            </Card>
                            }

                        </Card>
                        <Group position="center" mt="xl">
                            <Button onClick={prevStep} variant="outline">
                                Précédent
                            </Button>
                            <Button onClick={nextStep} disabled={emailStatus !== 5}>
                                Suivant
                            </Button>
                        </Group>
                    </Stepper.Step>

                    <Stepper.Step label="CV">
                        <Card mih={300} withBorder>
                            <Dropzone
                                style={{backgroundColor: '#F1F3F5'}}
                                onDrop={(f) => {
                                    setInputFile(f[0]);
                                    setFileStatus(1);
                                }}
                                onReject={(f) => console.log('rejected files', f)}
                                maxSize={2 * 1024 ** 2}
                                maxFiles={1}
                                accept={[
                                    'application/pdf'
                                ]}
                                loading={fileStatus === 4 ? true : false}
                                disabled={fileStatus === 5 ? true : false}
                                mt='xs'
                                mb='xs'
                            >
                                <Group position="center" spacing="xl" style={{ minHeight: 50, pointerEvents: 'none' }}>
                                    <Center>
                                        <Stack>
                                            <Text>Déposer ici votre CV au format PDF (maximum 2 Mo)</Text>
                                            <Text>ou bien cliquer sur cette zone pour sélectionner le fichier à importer</Text>
                                        </Stack>
                                    </Center>
                                </Group>
                            </Dropzone>
                            { (inputFile !== null && fileStatus > 0 && fileStatus < 5) &&
                            <>
                            <Text>
                                Fichier sélectionné : <strong>{inputFile.name}</strong>
                            </Text>
                            {pdfContent.length !== 0 && 
                            <Text color="dimmed" size="xs" maw={550}><strong>Extrait :</strong> {pdfContent.substring(0,400)}...</Text>
                            }
                            <Group position='center' pt='xs'>
                                <Button disabled={fileStatus < 3 || fileStatus > 4} loading={fileStatus === 4} 
                                    onClick={() => sendFile()} color='blue'
                                >
                                    <IconSend size={16} />
                                </Button>
                            </Group>
                            </>
                            }
                            {fileStatus === 5 && 
                            <Card>
                                <Text>Fichier transmis <strong>{inputFile?.name}</strong></Text>
                                <Text color='teal' mt='xs'><strong>
                                    CV déposé.
                                </strong></Text>
                            </Card>
                            }
                        </Card>
                        <Group position="center" mt="xl">
                            <Button onClick={prevStep} variant="outline">
                                Précédent
                            </Button>
                            <Button onClick={nextStep} disabled={fileStatus !== 5}>
                                Suivant
                            </Button>
                        </Group>
                    </Stepper.Step>

                    <Stepper.Step label="Qualification">
                        <Card mih={300} withBorder>
                            {repoStatus < 2 &&
                            <>
                            <Text>Merci de sélectionner un ou plusieurs <strong>métiers</strong> à partir de la liste ci-après :</Text>
                            <SelectorContainer
                                label="Vos métiers"
                                parents={listOfJobsParents}
                                children={listOfJobs}
                                handle={(a: string[]) => setInputJobs([...a])}
                            />
                            <Text mt='xs'>Merci de sélectionner un ou plusieurs <strong>secteurs</strong> à partir de la liste ci-après :</Text>
                            <SelectorContainer
                                label="Vos secteurs"
                                parents={[]}
                                children={listOfSectors}
                                handle={(a: string[]) => setInputSectors([...a])}
                            />
                            <Group position='center' pt='xs'>
                                <Button disabled={repoStatus < 1 || repoStatus > 2} loading={repoStatus === 2} 
                                    onClick={() => sendRepo()} color='blue'
                                >
                                    <IconSend size={16} />
                                </Button>
                            </Group>
                            </>
                            }
                            {repoStatus === 3 &&
                            <>
                            <Text>Métiers sélectionnés :</Text>
                            <Group>
                                {inputJobs.map((s) => <Badge key={`job-${s}`}>{s}</Badge>)}
                            </Group>
                            <Text mt='xs'>Secteurs sélectionnés :</Text>
                            <Group>
                                {inputSectors.map((s) => <Badge key={`sector-${s}`}>{s}</Badge>)}
                            </Group>
                            <Text mt="xs" color='teal'><strong>Qualification faite.</strong></Text>
                            </>
                            }
                        </Card>
                        <Group position="center" mt="xl">
                            <Button onClick={prevStep} variant="outline">
                                Précédent
                            </Button>
                            <Button onClick={nextStep} disabled={repoStatus !== 3}>
                                Suivant
                            </Button>
                        </Group>
                    </Stepper.Step>

                    <Stepper.Step label="Validation">
                        <Card mih={300} withBorder>
                            <Text>Pour finaliser votre dossier d'inscription,</Text>
                            <Text>merci de bien vouloir accepter les conditions générales d'utilsiation :</Text>
                            <Center mt='sm'>
                                <Button color='blue' variant='outline'
                                    size='sm' compact
                                    onClick={() => setDisplayAgreement(true)}
                                >
                                    Afficher les conditions générales de SNR PARTNERS
                                </Button>
                            </Center>
                            <Center mt="sm">
                                {finalStatus === 2 ?
                                <Text color='teal'><strong>
                                    Conditions générales de SNR PARTNERS acceptées.
                                </strong></Text>
                                :
                                <Button color='blue' onClick={() => sendAgreement()}
                                    disabled={finalStatus === 0 ? false : true}
                                    loading={finalStatus === 1 ? true : false}
                                >
                                    J'accepte les conditions générales de SNR PARTNERS
                                </Button>
                                }
                            </Center>
                        </Card>
                        <Group position="center" mt="xl">
                            <Button onClick={prevStep} variant="outline">
                                Précédent
                            </Button>
                            <Button onClick={nextStep} disabled={finalStatus !== 2}>
                                Suivant
                            </Button>
                        </Group>
                    </Stepper.Step>

                    <Stepper.Completed>
                        <Card mih={300} withBorder>
                            <Stack>
                                <Text color='teal'><strong>
                                    Merci pour votre inscription.
                                </strong></Text>
                                <Text>
                                    Nos équipes vont maintenant finaliser votre inscription.
                                </Text>
                                <Text>
                                    Vous serez ainsi systématiquement contacté sur toutes les opportunités de missions en lien avec vos compétences.
                                </Text>
                                <Text>
                                    En espérant que nous aurons rapidement l’occasion de travailler ensemble.
                                </Text>
                            </Stack>
                        </Card>
                        <Group position="center" mt="xl">
                            <Button onClick={prevStep} variant="outline">
                                Précédent
                            </Button>
                        </Group>
                    </Stepper.Completed>

                </Stepper>
                
                {errorMessage !== null &&
                <Alert mt='xs' icon={<IconAlertCircle size="1rem" />} title="Oops !" color="red" withCloseButton onClose={() => setErrorMessage(null)}>
                    {errorMessage}
                </Alert>
                }
                
            </Card>
        </Center>
        </>
    )
}

export { JoinPage }