import axios from 'axios';
import { AppShell, ColorScheme, MantineProvider } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { Routes, Route } from "react-router-dom";

import AppContext from './components/shared/AppContext';
import { HeaderContainer } from './components/shared/HeaderContainer';
import { FooterContainer } from './components/shared/FooterContainer';

import { ActivityReportPage } from './components/ActivityReportPage';
import { DocumentPage } from './components/DocumentPage';
import { FormPage } from './components/FormPage';
import { HomePage } from './components/HomePage';
import { JoinPage } from './components/JoinPage';
import { MentionsPage } from './components/MentionsPage';
import { NotFoundPage } from './components/NotFoundPage';

function App() {

  // We define the global variables to share inside the app via context component.
  // --> api address
  const apiAddress = 'https://snr-digital.com/api';
  //const apiAddress = 'http://localhost/snr-digital/api';
  // --> color scheme
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'color-scheme',
    defaultValue: 'light',
  });
  // --> http client
  const httpClient = axios.create();
  httpClient.interceptors.response.use((response) => {
    return response;
  }, (error) => {
      let m = '';
      if (error.response) {
          // Request made and server responded
          m = 'Axios, server error';
      } else if (error.request) {
          // The request was made but no response was received
          m = 'Axios, no server response';
      } else {
          // Something happened in setting up the request that triggered an Error
          m = 'Axios, unknown error';
      }
      return { data: { status: false, message: m } };
  });
  // --> version
  const version = '30';

  // Create an object to hold the global variables and functions to update them.
  const userSettings = {
    // --> api address
    apiAddress: apiAddress,
    // --> color scheme
    colorScheme: colorScheme,
    setColorScheme,
    // --> http client
    httpClient,
    // --> version
    version,
  }

  return (
    <AppContext.Provider value={userSettings}>
      <MantineProvider theme={{ colorScheme: userSettings.colorScheme }} withGlobalStyles withNormalizeCSS>
        <AppShell
          header={<HeaderContainer/>}
          footer={<FooterContainer/>}
        >
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/activity-report/:paramToken" element={<ActivityReportPage/>} />
            <Route path="/document/:paramCategory/:paramToken" element={<DocumentPage/>} />
            <Route path="/form/:paramToken" element={<FormPage/>} />
            <Route path="/join/:paramDomain/:paramSource" element={<JoinPage/>} />
            <Route path="/join/:paramDomain" element={<JoinPage/>} />
            <Route path="/mentions" element={<MentionsPage/>} />
            <Route path="*" element={<NotFoundPage/>} />
          </Routes>
        </AppShell>
      </MantineProvider>
    </AppContext.Provider>
  );
}

export default App;
