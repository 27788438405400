import { useContext, useEffect, useState } from 'react';
import { Badge, Button, Center, Divider, Grid, Group, RingProgress, Table, Text, Textarea } from '@mantine/core';
import { IconHelp } from '@tabler/icons';
import { useParams } from "react-router-dom";
import AppContext from '../shared/AppContext';

interface CellInterface {
    year: number,
    month: number,
    day: number,
    value: number,
}

type ParamInterface = {
    paramToken: string,
}

const ActivityReportPage = () => {

    const myContext = useContext(AppContext);
    const minChars = 200;
    const maxChars = 500;
    const { paramToken } = useParams<ParamInterface>();
    const [ myMonth, setMyMonth ] = useState((new Date()).getMonth() === 0 ? 12 : (new Date()).getMonth());
    const [ myYear, setMyYear ] = useState((new Date()).getMonth() === 0 ? (new Date()).getFullYear() - 1 : (new Date()).getFullYear());
    const [ myCalendar, setMyCalendar ] = useState<CellInterface[][] | null>(null);
    const [ myDays, setMyDays ] = useState(0);
    const [ myDescription, setMyDescription ] = useState('');

    const resetCalendar = () => {
        const y = myYear;
        const m = myMonth;
        const d: Date = new Date(y, m-1, 1);
        let j: number = d.getDay(); // Sunday - Saturday : 0 - 6
        j = (j === 0) ? 6 : j - 1;
        const C: CellInterface[][] = [];
        C.push([]);
        for(let i=0;i<j;i++){
            C[0].push({year: 0, month: 0, day: 0, value: 0});
        }
        while (true) {
            C[C.length-1].push({
                year: d.getFullYear(),
                month: d.getMonth()+1,
                day: d.getDate(),
                value: 0,
            });
            d.setDate(d.getDate() + 1);
            if (d.getMonth() + 1 !== m) {
                break;
            }
            if (d.getDay() === 1) {
                C.push([]);
            }
        }
        if (d.getDay() !== 0) {
            while (d.getDay() !== 0) {
                C[C.length-1].push({year: 0, month: 0, day: 0, value: 0});
                d.setDate(d.getDate() + 1);
            }
        }
        setMyCalendar(C);
    }

    const updateCalendar = (row: number, col: number) => {
        if (myCalendar === null) return;
        const o: number = myCalendar[row][col].value;
        const n: number = (o === 0) ? 1 : ((o === 1 ? 0.5 : 0));
        setMyDays(myDays - o + n);
        setMyCalendar(myCalendar.map((r,i) => {
            return r.map((c,j) => { 
                if (i === row && j === col) {
                    return {...c, value: n}
                } else {
                    return {...c}
                }
            })
        }));
    }

    useEffect(() => {
        resetCalendar();
    }, []); // eslint-disable-line

    return (
        <>
        <Group mb="md">
            <Text fw={700}>Compte rendu d'activité du mois de {myMonth} {myYear}</Text>
            <Divider orientation='vertical'/>
            <Text>"manager" chez "client"</Text>
        </Group>
        {myCalendar !== null &&
        <Grid>
            <Grid.Col sm={12} md={4}>
                <Center>
                    <div>
                        <Group mb="sm">
                            <Badge>Etape 1</Badge>
                            <Text size="sm">sélection des jours travaillés</Text>
                        </Group>
                        <Table horizontalSpacing={0} verticalSpacing={0} style={{width: '140px'}}>
                            <tbody>
                                <tr>
                                    <td>Lun.</td>
                                    <td>Mar.</td>
                                    <td>Mer.</td>
                                    <td>Jeu.</td>
                                    <td>Ven.</td>
                                    <td>Sam.</td>
                                    <td>Dim.</td>
                                </tr>
                                {myCalendar.map((row,iRow) => 
                                <tr key={`row-${iRow}`}>
                                    {row.map((col,iCol) => 
                                    <td key={`row-${iRow}-col-${iCol}`}>
                                        {col.day !== 0 &&
                                        <div onClick={() => updateCalendar(iRow, iCol)}>
                                            <Text color="dimmed" size="xs" align="center">{col.day}</Text>
                                            <Center p={0}>
                                                <RingProgress size={40} thickness={10} sections={[
                                                    { value: Math.ceil(100 * col.value), color: 'blue' }
                                                ]}/>
                                            </Center>
                                        </div>
                                        }
                                    </td>
                                    )}
                                </tr>
                                )}
                            </tbody>
                        </Table>
                        <Center>
                            {myDays === 0 ? 
                            <Badge color="red">0 jour</Badge>
                            :
                            <Badge color="green">{myDays} jours</Badge>
                            }
                        </Center>
                        <Group>
                            <Text size="sm"><IconHelp /></Text>
                            <div>
                                <Text size="sm">1 clic sur 1 rond pour 1 journée</Text>
                                <Text size="sm">1 autre clic pour 1/2 journée</Text>
                                <Text size="sm">1 autre clic pour supprimer</Text>
                            </div>
                        </Group>
                    </div>
                </Center>
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
                <Group mb="sm">
                    <Badge>Etape 2</Badge>
                    <Text size="sm">description de l'activité réalisée</Text>
                </Group>
                <Textarea
                    value={myDescription}
                    onChange={(event) => {
                        setMyDescription(event.target.value.substring(0,maxChars))
                    }}
                    minRows={15}
                    autosize
                />
                <Text color="dimmed" size="sm">Entre {minChars} et {maxChars} caractères.</Text>
                <Center>
                    {(myDescription.trim().length < minChars || myDescription.trim().length > maxChars) ? 
                    <Badge color="red">{myDescription.trim().length} caractères</Badge>
                    :
                    <Badge color="green">{myDescription.trim().length} caractères</Badge>
                    }
                </Center>
            </Grid.Col>
            <Grid.Col sm={12} md={2}>
                <Group mb="sm">
                    <Badge>Etape 3</Badge>
                    <Text size="sm">validation</Text>
                </Group>
                <Center>
                    <Button color="blue" variant="outline" disabled={myDays === 0 || myDescription.length < minChars}>
                        Valider
                    </Button>
                </Center>
            </Grid.Col>
        </Grid>
        }
        </>
    )

}

export { ActivityReportPage }