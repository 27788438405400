import { Center, Stack, Text } from '@mantine/core';

const HomePage = () => {

    return (
        <Center>
            <Stack>
                <Text>Bonjour,</Text>
                <Text>Ce site fait parti de la galaxie SNR Partners,</Text>
                <Text>pour accéder au site principal :</Text>
                <Text align="right" color="blue" component="a" href="https://snr-partners.com">snr-partners.com</Text>
            </Stack>
        </Center>
    )
}

export { HomePage }