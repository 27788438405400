import { useContext } from 'react';
import { useParams } from "react-router-dom";
import { Text } from '@mantine/core';
import AppContext from '../shared/AppContext';

type ParamInterface = {
    paramCategory: 'invoice',
    paramToken: string,
}

const DocumentPage = () => {

    const myContext = useContext(AppContext);
    const { paramCategory, paramToken } = useParams<ParamInterface>();
    const srcFile = `${myContext.apiAddress}/public?dom=SNR&action=get_${paramCategory}&token=${paramToken}`;

    if (paramToken !== undefined && paramToken.match(/^[a-zA-Z0-9-]{10,50}$/) !== null) {
        return (
            <>
            <div style={{width: 'calc(100vw - 50px)', height: 'calc(100vh - 100px)'}}>
                <embed 
                    src={srcFile}
                    type="application/pdf"
                    style={{width: '100%', height: '100%'}}
                />
            </div>
            </>
        )
    } else {
        return (
            <>
            <Text color='red'>No ressource for {paramToken} in {paramCategory}.</Text>
            </>
        )
    }
}

export { DocumentPage }