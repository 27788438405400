const listOfJobsParents = [
    {"p": null, "c": "ha", "l": "Achats"},
    {"p": null, "c": "bo", "l": "Back et Middle Office"},
    {"p": null, "c": "cl", "l": "Commercial"},
    {"p": null, "c": "cm", "l": "Communication"},
    {"p": null, "c": "dg", "l": "Direction Générale"},
    {"p": null, "c": "dp", "l": "Direction Projet"},
    {"p": null, "c": "xx", "l": "Divers"},
    {"p": null, "c": "ex", "l": "Expertise Sectorielle"},
    {"p": null, "c": "fi", "l": "Finance"},
    {"p": null, "c": "ho", "l": "Hôtels, Restaurants"},
    {"p": null, "c": "im", "l": "Immobilier, Moyens Généraux"},
    {"p": null, "c": "it", "l": "IT"},
    {"p": null, "c": "ju", "l": "Juridique"},
    {"p": null, "c": "ma", "l": "Marketing"},
    {"p": null, "c": "rd", "l": "R&D"},
    {"p": null, "c": "re", "l": "Retail"},
    {"p": null, "c": "rh", "l": "RH"},
    {"p": null, "c": "se", "l": "Sécurité"},
    {"p": null, "c": "sp", "l": "Supply-Chain Logistique"},
    {"p": null, "c": "si", "l": "Usine, Site Industriel"},
];

const listOfJobs = [
    {"p": "ha", "c": null, "l": "Acheteur"},
    {"p": "ha", "c": null, "l": "Directeur Achats"},
    {"p": "ha", "c": null, "l": "Directeur Projet Achats"},
    {"p": "ha", "c": null, "l": "Responsable Achats"},
    {"p": "bo", "c": null, "l": "Directeur Back Office"},
    {"p": "bo", "c": null, "l": "Directeur Projet Middle Office"},
    {"p": "bo", "c": null, "l": "Gestionnaire Back Office"},
    {"p": "bo", "c": null, "l": "Gestionnaire Middle Office"},
    {"p": "bo", "c": null, "l": "Responsable Back Office"},
    {"p": "bo", "c": null, "l": "Responsable Middle Office"},
    {"p": "cl", "c": null, "l": "Commercial"},
    {"p": "cl", "c": null, "l": "Directeur Centre d'Appels"},
    {"p": "cl", "c": null, "l": "Directeur Commercial"},
    {"p": "cl", "c": null, "l": "Directeur Export"},
    {"p": "cl", "c": null, "l": "Directeur Offres"},
    {"p": "cl", "c": null, "l": "Directeur Partenariats"},
    {"p": "cl", "c": null, "l": "Directeur Projet Centre d'Appels"},
    {"p": "cl", "c": null, "l": "Directeur Projet Commercial"},
    {"p": "cl", "c": null, "l": "Responsable Commercial"},
    {"p": "cl", "c": null, "l": "Responsable CRM"},
    {"p": "cl", "c": null, "l": "Responsable Export"},
    {"p": "cl", "c": null, "l": "Responsable Pricing"},
    {"p": "cm", "c": null, "l": "Directeur Communication"},
    {"p": "cm", "c": null, "l": "Directeur Développemenrt Durable"},
    {"p": "cm", "c": null, "l": "Directeur Projet RSE"},
    {"p": "cm", "c": null, "l": "Directeur Relations Institutionnelles"},
    {"p": "cm", "c": null, "l": "Directeur Relations Presse"},
    {"p": "cm", "c": null, "l": "Directeur RSE"},
    {"p": "cm", "c": null, "l": "Responsable Communication"},
    {"p": "cm", "c": null, "l": "Responsable Evénementiel"},
    {"p": "cm", "c": null, "l": "Responsable Relations Presse"},
    {"p": "dg", "c": null, "l": "DG"},
    {"p": "dg", "c": null, "l": "DGA"},
    {"p": "dg", "c": null, "l": "Directeur Agence"},
    {"p": "dg", "c": null, "l": "Directeur Département"},
    {"p": "dg", "c": null, "l": "Directeur Pôle"},
    {"p": "dg", "c": null, "l": "Directeur Région"},
    {"p": "dg", "c": null, "l": "Directeur Stratégie"},
    {"p": "dg", "c": null, "l": "Directeur Transformation"},
    {"p": "dg", "c": null, "l": "Responsable Agence"},
    {"p": "dg", "c": null, "l": "Responsable Pôle"},
    {"p": "dp", "c": null, "l": "Conducteur de Travaux"},
    {"p": "dp", "c": null, "l": "Directeur Montage Immobilier"},
    {"p": "dp", "c": null, "l": "Directeur Projet BTP"},
    {"p": "dp", "c": null, "l": "Directeur Projet Energie"},
    {"p": "dp", "c": null, "l": "Directeur Projet Ferroviaire"},
    {"p": "dp", "c": null, "l": "Directeur Projet FM"},
    {"p": "dp", "c": null, "l": "Directeur Projet Immobilier"},
    {"p": "dp", "c": null, "l": "Directeur Projet NR"},
    {"p": "dp", "c": null, "l": "Directeur Projet Télécoms"},
    {"p": "dp", "c": null, "l": "Directeur Projet Transport Urbain"},
    {"p": "dp", "c": null, "l": "directeur promotion immobilière"},
    {"p": "xx", "c": null, "l": "Agent Immobilier"},
    {"p": "xx", "c": null, "l": "Autre"},
    {"p": "xx", "c": null, "l": "Infirmière"},
    {"p": "xx", "c": null, "l": "Médecin"},
    {"p": "ex", "c": null, "l": "Expert Secteur ou Métier"},
    {"p": "fi", "c": null, "l": "Analyste Financier"},
    {"p": "fi", "c": null, "l": "Chef Comptable"},
    {"p": "fi", "c": null, "l": "Comptable"},
    {"p": "fi", "c": null, "l": "Contrôleur de Gestion"},
    {"p": "fi", "c": null, "l": "Contrôleur Financier"},
    {"p": "fi", "c": null, "l": "Contrôleur Interne"},
    {"p": "fi", "c": null, "l": "Credit Manager"},
    {"p": "fi", "c": null, "l": "DAF"},
    {"p": "fi", "c": null, "l": "Directeur Audit"},
    {"p": "fi", "c": null, "l": "Directeur Comptable"},
    {"p": "fi", "c": null, "l": "Directeur Contrôle de Gestion"},
    {"p": "fi", "c": null, "l": "Directeur Contrôle Financier"},
    {"p": "fi", "c": null, "l": "Directeur CSP"},
    {"p": "fi", "c": null, "l": "Directeur Financement"},
    {"p": "fi", "c": null, "l": "Directeur Investissements"},
    {"p": "fi", "c": null, "l": "Directeur M&A"},
    {"p": "fi", "c": null, "l": "Directeur Order to Cash"},
    {"p": "fi", "c": null, "l": "Directeur Projet Comptabilité"},
    {"p": "fi", "c": null, "l": "Directeur Projet Finance"},
    {"p": "fi", "c": null, "l": "Directeur Projet M&A"},
    {"p": "fi", "c": null, "l": "Directeur Projet Trésorerie"},
    {"p": "fi", "c": null, "l": "Directeur Trésorerie"},
    {"p": "fi", "c": null, "l": "Gestionnaire Crédit"},
    {"p": "fi", "c": null, "l": "Gestionnaire Trésorerie"},
    {"p": "fi", "c": null, "l": "RAF"},
    {"p": "fi", "c": null, "l": "Responsable Affacturage"},
    {"p": "fi", "c": null, "l": "Responsable Audit"},
    {"p": "fi", "c": null, "l": "Responsable Carve Out"},
    {"p": "fi", "c": null, "l": "Responsable Comptable"},
    {"p": "fi", "c": null, "l": "Responsable Consolidation"},
    {"p": "fi", "c": null, "l": "Responsable Contrôle de Gestion"},
    {"p": "fi", "c": null, "l": "Responsable Contrôle Financier"},
    {"p": "fi", "c": null, "l": "Responsable Contrôle Interne"},
    {"p": "fi", "c": null, "l": "Responsable Crédits banque"},
    {"p": "fi", "c": null, "l": "Responsable Facturation"},
    {"p": "fi", "c": null, "l": "Responsable Fiscal"},
    {"p": "fi", "c": null, "l": "Responsable M&A"},
    {"p": "fi", "c": null, "l": "Responsable Recouvrement"},
    {"p": "fi", "c": null, "l": "Responsable Trésorerie"},
    {"p": "fi", "c": null, "l": "Secrétaire Général"},
    {"p": "fi", "c": null, "l": "Trésorier"},
    {"p": "ho", "c": null, "l": "Directeur Restaurant"},
    {"p": "im", "c": null, "l": "Directeur Immobilier"},
    {"p": "im", "c": null, "l": "Responsable Flotte véhicules"},
    {"p": "im", "c": null, "l": "Responsable Services Généraux"},
    {"p": "it", "c": null, "l": "Administrateur BDD"},
    {"p": "it", "c": null, "l": "Administrateur Système IT"},
    {"p": "it", "c": null, "l": "AMOA IT"},
    {"p": "it", "c": null, "l": "Architecte IT"},
    {"p": "it", "c": null, "l": "Développeur IT"},
    {"p": "it", "c": null, "l": "Directeur BI"},
    {"p": "it", "c": null, "l": "Directeur Exploitation IT"},
    {"p": "it", "c": null, "l": "Directeur Infra IT"},
    {"p": "it", "c": null, "l": "Directeur Opérations IT"},
    {"p": "it", "c": null, "l": "Directeur projet IT"},
    {"p": "it", "c": null, "l": "DSI"},
    {"p": "it", "c": null, "l": "Expert BI"},
    {"p": "it", "c": null, "l": "Expert Data"},
    {"p": "it", "c": null, "l": "Expert IT"},
    {"p": "it", "c": null, "l": "Expert SAP"},
    {"p": "it", "c": null, "l": "Ingénieur IT"},
    {"p": "it", "c": null, "l": "Ingénieur Réseaux IT"},
    {"p": "it", "c": null, "l": "Responsable Applications IT"},
    {"p": "it", "c": null, "l": "Responsable Architecture IT"},
    {"p": "it", "c": null, "l": "Responsable BDD"},
    {"p": "it", "c": null, "l": "Responsable BI"},
    {"p": "it", "c": null, "l": "Responsable Contrôle de Gestion"},
    {"p": "it", "c": null, "l": "Responsable Data"},
    {"p": "it", "c": null, "l": "Responsable Delivery"},
    {"p": "it", "c": null, "l": "Responsable études IT"},
    {"p": "it", "c": null, "l": "Responsable Exploitation IT"},
    {"p": "it", "c": null, "l": "Responsable Infogérance"},
    {"p": "it", "c": null, "l": "Responsable Infra IT"},
    {"p": "it", "c": null, "l": "Responsable Maintenance IT"},
    {"p": "it", "c": null, "l": "Responsable prod IT"},
    {"p": "it", "c": null, "l": "Responsable Service Utilisateurs Informatique"},
    {"p": "it", "c": null, "l": "RSI"},
    {"p": "it", "c": null, "l": "RSSI"},
    {"p": "ju", "c": null, "l": "Administrateur Judiciaire"},
    {"p": "ju", "c": null, "l": "Avocat"},
    {"p": "ju", "c": null, "l": "Contract Manager"},
    {"p": "ju", "c": null, "l": "Directeur Affaires Réglementaires"},
    {"p": "ju", "c": null, "l": "Directeur Juridique"},
    {"p": "ju", "c": null, "l": "Directeur Projet Compliance"},
    {"p": "ju", "c": null, "l": "Directeur Projet Conformité"},
    {"p": "ju", "c": null, "l": "Directeur Projet RGPD"},
    {"p": "ju", "c": null, "l": "Directeur Risques"},
    {"p": "ju", "c": null, "l": "Juriste"},
    {"p": "ju", "c": null, "l": "Responsable Compliance"},
    {"p": "ju", "c": null, "l": "Responsable Conformité"},
    {"p": "ju", "c": null, "l": "Responsable Contentieux"},
    {"p": "ju", "c": null, "l": "Responsable Contrats"},
    {"p": "ju", "c": null, "l": "Responsable Juridique"},
    {"p": "ju", "c": null, "l": "Responsable RGPD"},
    {"p": "ju", "c": null, "l": "Risk Manager"},
    {"p": "ju", "c": null, "l": "Rresponsable Risques"},
    {"p": "ma", "c": null, "l": "Chef de produit"},
    {"p": "ma", "c": null, "l": "Directeur Marketing"},
    {"p": "ma", "c": null, "l": "Directeur produits"},
    {"p": "ma", "c": null, "l": "Directeur Projet Marketing"},
    {"p": "ma", "c": null, "l": "Responsable des études Marketing"},
    {"p": "ma", "c": null, "l": "Responsable Marketing"},
    {"p": "rd", "c": null, "l": "Directeur Innovation"},
    {"p": "rd", "c": null, "l": "Directeur Projet R&D"},
    {"p": "rd", "c": null, "l": "Directeur Scientifique"},
    {"p": "rd", "c": null, "l": "Responsable Innovation"},
    {"p": "rd", "c": null, "l": "Responsable R&D"},
    {"p": "re", "c": null, "l": "Directeur e-business"},
    {"p": "re", "c": null, "l": "Directeur e-commerce"},
    {"p": "re", "c": null, "l": "Directeur Enseigne/Boutique"},
    {"p": "re", "c": null, "l": "Directeur Retail"},
    {"p": "re", "c": null, "l": "Responsable Distribution"},
    {"p": "rh", "c": null, "l": "Contrôleur de Gestion Social"},
    {"p": "rh", "c": null, "l": "Directeur ADP"},
    {"p": "rh", "c": null, "l": "Directeur Comp&Ben"},
    {"p": "rh", "c": null, "l": "Directeur Développement RH"},
    {"p": "rh", "c": null, "l": "Directeur Formation"},
    {"p": "rh", "c": null, "l": "Directeur Paie"},
    {"p": "rh", "c": null, "l": "Directeur Projet NR"},
    {"p": "rh", "c": null, "l": "Directeur Projet Paie"},
    {"p": "rh", "c": null, "l": "Directeur Projet RH"},
    {"p": "rh", "c": null, "l": "Directeur Projet SIRH"},
    {"p": "rh", "c": null, "l": "Directeur Relations Sociales"},
    {"p": "rh", "c": null, "l": "Directeur SIRH"},
    {"p": "rh", "c": null, "l": "DRH"},
    {"p": "rh", "c": null, "l": "Gestionnaire Paie"},
    {"p": "rh", "c": null, "l": "Gestionnaire RH"},
    {"p": "rh", "c": null, "l": "Responsable ADP"},
    {"p": "rh", "c": null, "l": "Responsable Comp&Ben"},
    {"p": "rh", "c": null, "l": "Responsable Contrôle de Gestion Social"},
    {"p": "rh", "c": null, "l": "Responsable Développement RH"},
    {"p": "rh", "c": null, "l": "Responsable Formation"},
    {"p": "rh", "c": null, "l": "Responsable Mobilité RH"},
    {"p": "rh", "c": null, "l": "Responsable Paie"},
    {"p": "rh", "c": null, "l": "Responsable QVT"},
    {"p": "rh", "c": null, "l": "Responsable Recrutement"},
    {"p": "rh", "c": null, "l": "Responsable relations écoles"},
    {"p": "rh", "c": null, "l": "Responsable Relations Sociales"},
    {"p": "rh", "c": null, "l": "Responsable SIRH"},
    {"p": "rh", "c": null, "l": "RRH"},
    {"p": "se", "c": null, "l": "Responsable Sécurité"},
    {"p": "se", "c": null, "l": "Responsable Sécurité Incendie"},
    {"p": "se", "c": null, "l": "Responsable Sûreté"},
    {"p": "sp", "c": null, "l": "Chef de Projet S&OP"},
    {"p": "sp", "c": null, "l": "Chef de projet Supply-Chain"},
    {"p": "sp", "c": null, "l": "Chef de Quart"},
    {"p": "sp", "c": null, "l": "Directeur Logistique"},
    {"p": "sp", "c": null, "l": "Directeur Projet Logistique"},
    {"p": "sp", "c": null, "l": "Directeur Projet Supply-Chain"},
    {"p": "sp", "c": null, "l": "Directeur Service Après-Vente"},
    {"p": "sp", "c": null, "l": "Directeur Service Clients"},
    {"p": "sp", "c": null, "l": "Directeur Site Logistique"},
    {"p": "sp", "c": null, "l": "Directeur Supply-Chain"},
    {"p": "sp", "c": null, "l": "Directeur Transport"},
    {"p": "sp", "c": null, "l": "Expert WMS"},
    {"p": "sp", "c": null, "l": "Gestionnaire ADV"},
    {"p": "sp", "c": null, "l": "Gestionnaire Approvisionnements"},
    {"p": "sp", "c": null, "l": "Gestionnaire Logistique"},
    {"p": "sp", "c": null, "l": "Responsable ADV"},
    {"p": "sp", "c": null, "l": "Responsable Affrètement"},
    {"p": "sp", "c": null, "l": "Responsable Approvisionnements"},
    {"p": "sp", "c": null, "l": "Responsable Caristes"},
    {"p": "sp", "c": null, "l": "Responsable Douane"},
    {"p": "sp", "c": null, "l": "Responsable Expedition"},
    {"p": "sp", "c": null, "l": "Responsable Expédition"},
    {"p": "sp", "c": null, "l": "Responsable Exploitation Logistique"},
    {"p": "sp", "c": null, "l": "Responsable Logistique"},
    {"p": "sp", "c": null, "l": "Responsable Master Data"},
    {"p": "sp", "c": null, "l": "Responsable Ordonnancement"},
    {"p": "sp", "c": null, "l": "Responsable PDP"},
    {"p": "sp", "c": null, "l": "Responsable Prise de Commandes"},
    {"p": "sp", "c": null, "l": "Responsable Réceptions"},
    {"p": "sp", "c": null, "l": "Responsable S&OP"},
    {"p": "sp", "c": null, "l": "Responsable Service Clients"},
    {"p": "sp", "c": null, "l": "Responsable Stocks"},
    {"p": "sp", "c": null, "l": "Responsable Supply-Chain"},
    {"p": "sp", "c": null, "l": "Responsable Transport"},
    {"p": "si", "c": null, "l": "Délégué Pharmaceutique"},
    {"p": "si", "c": null, "l": "DG"},
    {"p": "si", "c": null, "l": "Directeur Adjoint Usine"},
    {"p": "si", "c": null, "l": "Directeur BE"},
    {"p": "si", "c": null, "l": "Directeur de Projet Industriel"},
    {"p": "si", "c": null, "l": "Directeur études"},
    {"p": "si", "c": null, "l": "Directeur HSE"},
    {"p": "si", "c": null, "l": "Directeur Industriel"},
    {"p": "si", "c": null, "l": "Directeur Laboratoire"},
    {"p": "si", "c": null, "l": "Directeur Maintenance"},
    {"p": "si", "c": null, "l": "Directeur Médical"},
    {"p": "si", "c": null, "l": "Directeur Méthodes"},
    {"p": "si", "c": null, "l": "Directeur Opérations"},
    {"p": "si", "c": null, "l": "Directeur Production"},
    {"p": "si", "c": null, "l": "Directeur Projet HSE"},
    {"p": "si", "c": null, "l": "Directeur Projet industriel"},
    {"p": "si", "c": null, "l": "Directeur Projet Ingénierie"},
    {"p": "si", "c": null, "l": "Directeur Projet Lean"},
    {"p": "si", "c": null, "l": "Directeur Projet QHSE"},
    {"p": "si", "c": null, "l": "Directeur Projet QSE"},
    {"p": "si", "c": null, "l": "Directeur Projet Qualité"},
    {"p": "si", "c": null, "l": "Directeur Projet R&D"},
    {"p": "si", "c": null, "l": "Directeur QHSE"},
    {"p": "si", "c": null, "l": "Directeur Qualité"},
    {"p": "si", "c": null, "l": "Directeur R&D"},
    {"p": "si", "c": null, "l": "Directeur Technique"},
    {"p": "si", "c": null, "l": "Directeur Usine"},
    {"p": "si", "c": null, "l": "Direction de Projet HSE"},
    {"p": "si", "c": null, "l": "Expert Lean"},
    {"p": "si", "c": null, "l": "Ingénieur"},
    {"p": "si", "c": null, "l": "Ingénieur études"},
    {"p": "si", "c": null, "l": "Libérateur de Lots"},
    {"p": "si", "c": null, "l": "Pharmacien Responsable"},
    {"p": "si", "c": null, "l": "Responsable Affaires Pharmaceutiques"},
    {"p": "si", "c": null, "l": "Responsable atelier"},
    {"p": "si", "c": null, "l": "Responsable BE"},
    {"p": "si", "c": null, "l": "Responsable Conditionnement"},
    {"p": "si", "c": null, "l": "Responsable Essais"},
    {"p": "si", "c": null, "l": "Responsable HSE"},
    {"p": "si", "c": null, "l": "Responsable Industrialisation"},
    {"p": "si", "c": null, "l": "Responsable Industriel"},
    {"p": "si", "c": null, "l": "Responsable Laboratoire"},
    {"p": "si", "c": null, "l": "Responsable Maintenance"},
    {"p": "si", "c": null, "l": "Responsable Méthodes"},
    {"p": "si", "c": null, "l": "Responsable Opérations"},
    {"p": "si", "c": null, "l": "Responsable Production"},
    {"p": "si", "c": null, "l": "Responsable QHSE"},
    {"p": "si", "c": null, "l": "Responsable QSE"},
    {"p": "si", "c": null, "l": "Responsable Qualification et Tests"},
    {"p": "si", "c": null, "l": "Responsable Qualité"},
    {"p": "si", "c": null, "l": "Responsable R&D"},
    {"p": "si", "c": null, "l": "Responsable Salle de Pesées"},
    {"p": "si", "c": null, "l": "Responsable Technique"},
    {"p": "si", "c": null, "l": "Responsable Travaux Neufs"},
];

const listOfSectors = [
    { "p": null, "c": null, "l": "Associatif"},
    { "p": null, "c": null, "l": "Banque, Assurance"},
    { "p": null, "c": null, "l": "BTP, Immobilier"},
    { "p": null, "c": null, "l": "Éditeur de Logiciels"},
    { "p": null, "c": null, "l": "Énergie, Eau, Environnement"},
    { "p": null, "c": null, "l": "Ferroviaire"},
    { "p": null, "c": null, "l": "FM, Propreté, Nettoyage, Maintenance"},
    { "p": null, "c": null, "l": "Hôtellerie, Restauration"},
    { "p": null, "c": null, "l": "Industrie Aéronautique Défense Spatial"},
    { "p": null, "c": null, "l": "Industrie Agroalimentaire"},
    { "p": null, "c": null, "l": "Industrie Automobile"},
    { "p": null, "c": null, "l": "Industrie Bijouterie, Horlogerie, Joaillerie, Maroquinerie, Prêt à Porter"},
    { "p": null, "c": null, "l": "Industrie Biotech"},
    { "p": null, "c": null, "l": "Industrie Bois Papier Carton"},
    { "p": null, "c": null, "l": "Industrie Céramique, terre cuite"},
    { "p": null, "c": null, "l": "Industrie Chimique, Cosmétique"},
    { "p": null, "c": null, "l": "Industrie des Dispositifs Médicaux"},
    { "p": null, "c": null, "l": "Industrie des Jeux"},
    { "p": null, "c": null, "l": "Industrie des Matériaux de Construction"},
    { "p": null, "c": null, "l": "Industrie du Meuble"},
    { "p": null, "c": null, "l": "Industrie Electroménager"},
    { "p": null, "c": null, "l": "Industrie Electronique"},
    { "p": null, "c": null, "l": "Industrie Emballage, Packaging, Embouteillage"},
    { "p": null, "c": null, "l": "Industrie FMCG"},
    { "p": null, "c": null, "l": "Industrie High-Tech"},
    { "p": null, "c": null, "l": "Industrie Imprimerie"},
    { "p": null, "c": null, "l": "Industrie IT"},
    { "p": null, "c": null, "l": "Industrie Machine, Outils, Equipements, Matériels"},
    { "p": null, "c": null, "l": "Industrie Mécanique, électromécanique"},
    { "p": null, "c": null, "l": "Industrie Menuiserie"},
    { "p": null, "c": null, "l": "Industrie Métallurgie"},
    { "p": null, "c": null, "l": "Industrie Mines et Métaux"},
    { "p": null, "c": null, "l": "Industrie Mobilier"},
    { "p": null, "c": null, "l": "Industrie Navale, Maritime"},
    { "p": null, "c": null, "l": "Industrie NR"},
    { "p": null, "c": null, "l": "Industrie Nutrition Animale"},
    { "p": null, "c": null, "l": "Industrie Pharmaceutique"},
    { "p": null, "c": null, "l": "Industrie Plasturgique et Caoutchouc"},
    { "p": null, "c": null, "l": "Industrie Tabac"},
    { "p": null, "c": null, "l": "Industrie Textile"},
    { "p": null, "c": null, "l": "Industrie Vélo"},
    { "p": null, "c": null, "l": "Industrie Verre"},
    { "p": null, "c": null, "l": "Ingénierie, études techniques"},
    { "p": null, "c": null, "l": "Installation électrique, Climatisation"},
    { "p": null, "c": null, "l": "Laboratoires d'Analyse"},
    { "p": null, "c": null, "l": "Médico-Social"},
    { "p": null, "c": null, "l": "Mutuelle"},
    { "p": null, "c": null, "l": "Public"},
    { "p": null, "c": null, "l": "Retail, Distribution"},
    { "p": null, "c": null, "l": "Santé, Médical"},
    { "p": null, "c": null, "l": "Services Aide à la Personne"},
    { "p": null, "c": null, "l": "Services Aménagement"},
    { "p": null, "c": null, "l": "Services Compta Audit, Juridique"},
    { "p": null, "c": null, "l": "Services Consulting"},
    { "p": null, "c": null, "l": "Services ESN"},
    { "p": null, "c": null, "l": "Services Ingénierie, études techniques"},
    { "p": null, "c": null, "l": "Services IT"},
    { "p": null, "c": null, "l": "Services Leasing"},
    { "p": null, "c": null, "l": "Services NR"},
    { "p": null, "c": null, "l": "Services Publicitaires, Agence de Communication, Médias"},
    { "p": null, "c": null, "l": "Start-Up"},
    { "p": null, "c": null, "l": "Télécoms"},
    { "p": null, "c": null, "l": "Tourisme, Loisirs, Voyages"},
    { "p": null, "c": null, "l": "Transport, Logistique"},
];

export { listOfJobsParents, listOfJobs, listOfSectors }